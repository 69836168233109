import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import Days from "./common_dashboard";
import { FormattedMessage, injectIntl } from "react-intl";
import { DatePartLocalised } from "../utility/date_part_localised";

class CalendarDashboard extends Component {
  updateOnPropertyChanges(property_id) {
    if (!this.props.testMode) {
      const id = property_id ? property_id : localStorage.getItem("property_id");
      // TODO: Modernise this component to use hooks
      if (id !== undefined && id !== null) {
        this.props.calendarData(id);
      }
    }
  }

  componentDidMount() {
    this.updateOnPropertyChanges();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property !== this.props.property) {
      this.updateOnPropertyChanges(this.props.property.id);
    }
  }

  mobileCalendarView(calendar) {
    const date1 = { date: calendar[0]["date"], available: calendar[0]["available"] };
    const calendarDays = new Days(
      date1,
      calendar[this.props.calendar.length - 1]["date"],
      this.props.intl
    );
    const diff = calendarDays.getGapDays();
    return (
      <div className="row">
        <div className="col s12 days">{diff.days}</div>
        <div className="col s12">{diff.msg}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="col s6">
        <NavLink exact to="/calendar/">
          <div className="card">
            <div className="cardHeader">
              <span className="title">
                <FormattedMessage
                  id="dashboard.calendar.title"
                  defaultMessage="Calendar"
                />
              </span>
              <span className="chevron hide-on-small-only">
                <Icon
                  icon={ICONS.CHEVRON.d}
                  width={ICONS.CHEVRON.width}
                  height={ICONS.CHEVRON.height}
                />
              </span>
            </div>
            <div className="card-content">
              {this.props.calendar ? (
                <div>
                  {this.props.calendar.length > 0 ? (
                    <div>
                      <div className="hide-on-med-and-up">
                        {this.mobileCalendarView(this.props.calendar)}
                      </div>
                      <div className="date-container hide-on-small-only">
                        <div className="date-container-l">
                          <Icon
                            icon={ICONS.CALENDAR_2.d}
                            width={76}
                            height={83}
                            viewBox="0 0 81 88"
                          />
                          <span className="date-day">
                            <DatePartLocalised
                              date={this.props.calendar[0]["date"]}
                              options={{ day: "numeric" }}
                            />
                          </span>
                          <span className="date-month">
                            <DatePartLocalised
                              date={this.props.calendar[0]["date"]}
                              options={{ month: "long" }}
                            />
                          </span>
                        </div>
                        <div className="arrow-container">
                          <div className="line" />
                          <div className="status">
                            {this.props.calendar[0]["available"] === true ? (
                              <FormattedMessage
                                id="dashboard.calendar.available"
                                defaultMessage="Available"
                              />
                            ) : (
                              <FormattedMessage
                                id="dashboard.calendar.blocked"
                                defaultMessage="Blocked"
                              />
                            )}
                          </div>
                          <div className="line" />
                          <div className="line-chevron">
                            <Icon
                              icon={ICONS.CHEVRON.d}
                              width={10}
                              height={22}
                              color="#d6d6d6"
                            />
                          </div>
                        </div>
                        <div className="date-container-r">
                          <Icon
                            icon={ICONS.CALENDAR_2.d}
                            width={76}
                            height={83}
                            viewBox="0 0 81 88"
                          />
                          <span className="date-day">
                            <DatePartLocalised
                              date={
                                this.props.calendar[this.props.calendar.length - 1][
                                  "date"
                                ]
                              }
                              options={{ day: "numeric" }}
                            />
                          </span>
                          <span className="date-month">
                            <DatePartLocalised
                              date={
                                this.props.calendar[this.props.calendar.length - 1][
                                  "date"
                                ]
                              }
                              options={{ month: "long" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <FormattedMessage
                        id="dashboard.calendar.no_information"
                        defaultMessage="No current calendar information!"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <SmallSpinner />
              )}
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    property: state.property.property_info,
    calendar: state.calendar.calendar_dashboard,
  };
}

export default connect(mapStateToProps, actions)(injectIntl(CalendarDashboard));
